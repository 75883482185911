import React, { Component } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import MainLayout from './MainLayout'
import { MediaQueryProvider, MediaQuery } from 'react-responsive-hoc'
import { connect } from 'react-redux'
import MobileBook from '../components/MobileBook/MobileBook'
import CenterOverlay from '../components/CenterOverlay/CenterOverlay'
import IntroLargeTitle from '../components/IntroLargeTitle/IntroLargeTitle'
import Lead from '../components/Lead/Lead'
import FlexibleContent from '../components/FlexibleContent/FlexibleContent'
import getLanguageCode from '../utils/getLanguageCode'

class DefaultPromotionsTemplate extends Component {
  componentDidMount () {
    const locale = this.props.pageContext.locale
    const translations = this.props.pageContext.translations
    const langData = { locale, translations }
    this.props.setLangData(langData)
    this.props.showHeroBorder(true)
  }

  render () {
    const metaTitle = this.props.data.page.acf.metaTitle
    const metaDescription = this.props.data.page.acf.metaDescription
    const locale = this.props.pageContext.locale
    const longTitle = this.props.data.page.acf.long_title
    const lead = this.props.data.page.acf.lead
    const bookContent = this.props.data.bookContent
    const bookFormHeader = {
      label: bookContent.room.dropdown.label,
      title: bookContent.room.dropdown.title
    }
    const bookFormLabels =
      bookContent.room.dropdown.content.book_form.book_form_labels
    this.props.data.page.mainHeader = this.props.data.mainHeader
    const languageCode = getLanguageCode(locale)
    const navMenuRight = this.props.data.mainHeader.nav_menu_right
    const phoneLink = navMenuRight.nav.items.find(
      item => item.attr.includes('phone-link')
    )

    return (
      <div>
        <Helmet htmlAttributes={{ lang: languageCode }}>
          <title>{metaTitle}</title>
          <meta name='description' content={metaDescription} />
          <link
            rel='canonical'
            href={`${process.env.GATSBY_SITE_URL}${this.props.data.page.path}`}
          />
          {this.props.pageContext.translations.map(
            ({ locale_name, path }, i) => {
              return (
                <link
                  key={i}
                  rel='alternate'
                  hreflang={locale_name.replace('_', '-')}
                  href={`${process.env.GATSBY_SITE_URL}${path}`}
                />
              )
            }
          )}
          {this.props.pageContext.translations.map(
            ({ native_name, path }, i) => {
              if (native_name === 'English') {
                return (
                  <link
                    key={i}
                    rel='alternate'
                    hreflang='x-default'
                    href={`${process.env.GATSBY_SITE_URL}${path}`}
                  />
                )
              }
            }
          )}
        </Helmet>
        <MainLayout
          mainHeaderData={this.props.data.mainHeader}
          bookContent={bookContent}
          locale={locale}
          locationKey={this.props.location.key || ''}
          translations={this.props.pageContext.translations}
        >
          <div>
            <MediaQueryProvider width={1440} height={900}>
              <MediaQuery query='(max-width: 977px)'>
                <MobileBook
                  locale={locale}
                  labels={bookFormLabels}
                  header={bookFormHeader}
                  phoneLink={phoneLink}
                  centerLayout
                />
              </MediaQuery>
            </MediaQueryProvider>
          </div>
          <CenterOverlay noTopPadding={false}>
            <IntroLargeTitle text={longTitle} />
            <Lead text={lead} centered />
            <FlexibleContent
              data={this.props.data.page}
              locale={locale}
              leftOverlay
            />
          </CenterOverlay>
        </MainLayout>
      </div>
    )
  }
}

DefaultPromotionsTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

const mapStateToProps = ({ showHeroBorder, setLangData }) => {
  return { showHeroBorder, setLangData }
}

const mapDispatchToProps = dispatch => {
  return {
    showHeroBorder: show =>
      dispatch({
        type: `SHOW_DESKTOP_HEADER_BORDER`,
        showBorder: show
      }),
    setLangData: langData =>
      dispatch({
        type: `LANG_DATA`,
        langData
      })
  }
}

const PromotionsTemplate = connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultPromotionsTemplate)

export default PromotionsTemplate

export const pageQuery = graphql`
  query promotionsPageQuery($id: String!, $locale: String!) {
    page: wordpressPage(id: { eq: $id }) {
      path
      id
      slug
      title
      acf {
        long_title
        metaTitle: meta_title
        metaDescription: meta_description
        lead
      }
      ...FlexibleContentData
    }
    bookContent: wordpressStickyBook(language_code: { eq: $locale }) {
      ...StickyBookContent
    }
    mainHeader: wordpressMainHeader(language_code: { eq: $locale }) {
      ...MainHeaderContent
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
